<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import appConfig from "../../../../app.config.json";

import Widgets from "../components/widget.vue";
import Schedules from "../components/schedule.vue";
import Project from "../components/projects.vue";
import Task from "../components/tasks.vue";
import Member from "../components/members.vue";
import Chat from "../components/chat.vue";
import Status from "../components/status.vue";
import ActiveProjects from "../components/active-projects.vue";

export default {
    page: {
        title: "Dashboard",
        meta: [{ name: "description", content: appConfig.description }],
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$store.dispatch("Dashboard/setLinks", "Projects");
        });
    },
    data() {
        return {
            title: "Dashboard",
            items: [
                {
                    text: "Dashboards",
                    href: "/",
                },
                {
                    text: "Statistics",
                    active: true,
                },
            ],
        }
    },
    components: {
        Layout,
        PageHeader,
        Widgets,
        Schedules,
        Project,
        Task,
        Member,
        Chat,
        ActiveProjects,
        Status
    },
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row project-wrapper">
            <div class="col-xxl-8">
                <div class="row">

                    <Widgets />

                    <div class="col-xl-4">

                    </div>
                    <div class="col-xl-4">

                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <Project/>
                    </div>
                </div>
            </div>

            <div class="col-xxl-4">
                <Schedules />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-7">
                <ActiveProjects />
            </div>
            <div class="col-xl-5">
                <Task />
            </div>
        </div>

        <div class="row">
            <div class="col-xxl-4">
                <Member />
            </div>
            <div class="col-xxl-4 col-lg-6">
                <Chat />
            </div>
            <div class="col-xxl-4 col-lg-6">
                <Status />
            </div>
        </div>

    </Layout>
</template>
